import React from 'react'
import { SiteLogo } from '../../components/SiteLogo'
import { LoginForm } from '../../components/LoginForm'
import LoginBackground from '../../images/backgrounds/login-background.png'
import './../../scss/main.scss'

export const LoginPageLayout = () => {
  return (
    <main className="login-section">
      <div className="login-section__wrapper">
        <div className="login-section__form">
          <SiteLogo path={'/'} />
          <LoginForm />
        </div>
        <div className="login-section__background">
          <img src={LoginBackground} alt="" />
        </div>
      </div>
    </main>
  )
}
