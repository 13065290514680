import React, { useState, createRef } from 'react'
import { Link, navigate } from 'gatsby'
import { useForm } from 'react-hook-form'

import { Label } from '../Label'
import { Input } from '../Input'

export const LoginForm = () => {
  const [loginValue, setLoginValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const { register, handleSubmit, errors } = useForm()
  const passwordRef = createRef()

  const submitCallback = () => {
    navigate('/dashboard', { replace: true, state: { firstLogin: true } })
  }

  const handleClick = () => {
    setLoginValue('558 558')
    setPasswordValue('333 444')
  }

  return (
    <section className="login-form">
      <h1 className="heading-1">Log In</h1>
      <form
        className="login-form__form"
        onSubmit={handleSubmit(submitCallback)}
      >
        <fieldset>
          <Label labelFor="idnumber" text="ID Number" />
          <Input
            type="text"
            name="idnumber"
            onClick={handleClick}
            onChange={e => setLoginValue(e.currentTarget.value)}
            value={loginValue}
            register={register}
            required
          />
          {errors.idnumber && (
            <span className="login-form__error">This field is required</span>
          )}
        </fieldset>
        <fieldset>
          <Label labelFor="Password" text="Password" />
          <Input
            type="password"
            name="password"
            onClick={handleClick}
            onChange={e => setPasswordValue(e.currentTarget.value)}
            value={passwordValue}
            reference={passwordRef}
            register={register}
            required
          />
          {errors.password && (
            <span className="login-form__error">This field is required</span>
          )}
        </fieldset>
        <p className="login-form__recover">
          <Link to="/">Recovery password</Link>
        </p>
        <input
          type="submit"
          className="btn btn--primary"
          to="/dashboard"
          value="Log in"
        />
        <p className="login-form__desc">
          Don&apos;t have an account?{' '}
          <Link to="/dashboard">Create an account</Link>
        </p>
      </form>
    </section>
  )
}
