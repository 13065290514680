import React from 'react'
import { SEO } from '../components/SEO'
import { LoginPageLayout } from '../layouts/LoginPageLayout'

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <LoginPageLayout />
  </>
)

export default IndexPage
