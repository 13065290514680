import React from 'react'
import { Link } from 'gatsby'
import logoSVG from './../../images/svg/site-logo.svg'

export const SiteLogo = ({ path = "/dashboard" }) => {
  return (
    <Link className="site-logo" to={path}>
      <img src={logoSVG} alt="Future Bank" />
    </Link>
  )
}