import React from 'react'

export const Input = ({ type = 'text', id, name, onChange, onClick, value, inputPlaceholder, required, register, error }) => {
  return (
    <input 
      type={type} 
      id={id} 
      name={name} 
      onChange={onChange} 
      onClick={onClick}
      value={value}
      ref={register({ required })}
      placeholder={error ? `${name} is required` : inputPlaceholder}
    />
  )
}
